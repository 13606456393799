import { Link } from "react-router-dom";
import Header from "../components/Header";
import heroBg from '../images/hero_bg.png';
import Footer from "../components/Footer";
import LocalPhoneIcon from '@mui/icons-material/LocalPhone';
import EmailIcon from '@mui/icons-material/Email';
import LocationOnIcon from '@mui/icons-material/LocationOn';
function ContactUs() {

    const backgroundStyle = {
        backgroundImage: `url(${heroBg})`,
    };

    return (
        <>
            <Header />
            <section className="hero_secc bg-style" style={backgroundStyle}>
                <div className="container">
                    <div className="about-sec-inner">
                        <div className="about-left-content">
                            <div className="hero_content-about">
                                <h2>
                                Contact Us
                                </h2>
                                <p><Link to="/">Home</Link> <span> > </span> Contact Us</p>
                            </div>
                        </div>
                        <div className="about-right-img">
                            <div className="hero_img-about">
                            
                                    <img src="about_hero.png"
                                        alt="img" />
                             
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            
          

            <section className="reveal py ">
                <div className="container">
                <div className="contact_expertise_main_col  justify-between flex expertise_box">
                        <div className="contact_phone_main justify-between flex">
                            <div className="contact_call_logo">
                            <Link to="tel: +91 7018 152390"> <span><LocalPhoneIcon /></span></Link>
                            </div>
                            <div class="vertical-line"></div>
                            <div className="contact_phone">
                                <span>Phone</span> <br/>
                                <Link to="tel: +91 7018 152390">+91 7018 152390</Link> <br />
                                <Link to="tel: +1 4385238809">+1 4385238809</Link>
                            </div>
                        </div>
                        <div className="contact_phone_main flex">
                            <div className="contact_call_logo">
                            <Link to="mailto:info@exoticaitsolutions.com" > <span><EmailIcon/></span></Link>
                            </div>
                            <div class="vertical-line"></div>
                            <div className="contact_phone">
                                <span>Email</span> <br/>
                                <Link to="mailto:info@exoticaitsolutions.com" >info@exoticaitsolutions.com</Link> <br />
                                <Link to="mailto:sales@exoticaitsolutions.com" >sales@exoticaitsolutions.com</Link>
                            </div>
                        </div>
                        <div className="contact_phone_main flex">
                            <div className="contact_call_logo">
                            <Link to="https://www.google.com/maps/place/Exotica+IT+Solutions+Private+Limited/@30.708842,76.701725,16z/data=!4m6!3m5!1s0x390fef9d63bc66f1:0x321d9a798e5609f4!8m2!3d30.7088424!4d76.7017254!16s%2Fg%2F11j10qzr8j?hl=en&entry=ttu&g_ep=EgoyMDI0MDgyMC4xIKXMDSoASAFQAw%3D%3D" target="_blank"> <span><LocationOnIcon/></span></Link>   
                            </div>
                            <div class="vertical-line"></div>
                            <div className="contact_phone">
                                <span>Location</span><br/>
                                <Link to="https://www.google.com/maps/place/Exotica+IT+Solutions+Private+Limited/@30.708842,76.701725,16z/data=!4m6!3m5!1s0x390fef9d63bc66f1:0x321d9a798e5609f4!8m2!3d30.7088424!4d76.7017254!16s%2Fg%2F11j10qzr8j?hl=en&entry=ttu&g_ep=EgoyMDI0MDgyMC4xIKXMDSoASAFQAw%3D%3D" target="_blank">385 Edgevalley Road London, Ontario, N5v0c2</Link> 
                             
                            </div>
                        </div>
                    </div>

                    <div class="contact_main  ">
                        <div class="heading-title">
                            <h2><span> Quick Contact Us</span></h2>
                        </div>
                        <div class="home_contactform">
                            <form action="#" class="contFrm" method="POST">
                                <div class="inner_contact">
                                    <div class="field">
                                        <label class="contact-form__label" for="firstName" >First name</label><br />
                                        <input type="text" class="contact-form__input" placeholder="Kerry" required />
                                    </div>

                                    <div class="field">
                                        <label class="contact-form__label" for="lastName" placeholder="Last name">Last name</label><br />
                                        <input type="text" class="contact-form__input" placeholder="Josph" required />
                                    </div>

                                    <div class="field">
                                        <label class="contact-form__label" for="email" placeholder="email">Email</label><br />
                                        <input type="email" class="contact-form__input" placeholder="example@example.com" required />
                                    </div>

                                    <div class="field">
                                        <label class="contact-form__label" for="Phone" placeholder="Phone">Phone</label><br />
                                        <input type="tel" name="phone" placeholder="Phone Number" class="contact-form__input" required inputMode="numeric"
                                            pattern="[0-9]*"
                                            maxLength="10"
                                            onKeyPress={(e) => {
                                                if (!/[0-9]/.test(e.key)) {
                                                    e.preventDefault();
                                                }
                                            }} />
                                    </div>

                                    <div class="field field_textarea">
                                        <label class="contact-form__label" for="message">Message</label><br />
                                        <textarea class="contact-form__text" id="message" name="message" placeholder="Write a few words!" required ></textarea>
                                    </div>

                                    <div class="submit_btn">
                                        <input class="contact-form__submit" type="submit" value="Send" />
                                    </div>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </section >
            <section className="reveal">
                    <div class="map-container">
                        <iframe class="map-iframe" src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3430.3397779723773!2d76.69915047447073!3d30.708846986788608!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x390fef9d63bc66f1%3A0x321d9a798e5609f4!2sExotica%20IT%20Solutions%20Private%20Limited!5e0!3m2!1sen!2sin!4v1707735731389!5m2!1sen!2sin"  title="Location of Exotica IT Solutions" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe>
                    </div>
            </section>
            <Footer />
        </>
    )
}
export default ContactUs;