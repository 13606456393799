import { Link, useLocation  } from 'react-router-dom';
import { motion } from 'framer-motion';
import { useEffect, useState } from 'react';
import { handleNavbarScroll, initializeHoverEffect, initScrollAnimations, initHamburgerMenu } from '../js/main';
import $ from 'jquery';

function Header() {
  const [activeButton, setActiveButton] = useState('');
  const location = useLocation();

  useEffect(() => {
    const cleanupScroll = handleNavbarScroll();
    initializeHoverEffect();
    initScrollAnimations();
    initHamburgerMenu();

 
    setActiveButton(location.pathname);

    return () => {
      cleanupScroll();
      $(".hamburger").off("click");
      $("#menu-header-menu li Link").off("click");
    };
  }, [location]);

  const handleButtonClick = (path) => {
    setActiveButton(path);
  };

  return (
    <header id="header" className="header">
      <div className="header_inner flex justify-between align-center">
        <div className="logo">
          <motion.img
            src="logo.png"
            alt="logo"
            initial={{ x: '-100vw', opacity: 0, rotateY: -90 }}
            animate={{ x: 0, opacity: 1, rotateY: 0 }}
            transition={{ duration: 2, ease: 'easeOut' }}
            style={{ perspective: 1000 }}
          />
        </div>
        <nav className="navbar">
          <div className="second_header_menu mobile-menu" id="mobile_menu_toggle">
            <div className="hamburger">
              <span className="bar"></span>
              <span className="bar"></span>
              <span className="bar"></span>
            </div>
            <ul id="menu-header-menu" className="bottom_menu_bars flex justify-between align-center">
            <li>
                <Link 
                  to="/" 
                  className={activeButton === '/' ? 'theme_btn' : ''}
                  onClick={() => handleButtonClick('#')}
                >
                  Home
                </Link>
              </li>
              <li>
                <Link 
                  to="/about" 
                  className={activeButton === '/about' ? 'theme_btn' : ''}
                  onClick={() => handleButtonClick('/about_us')}
                >
                  About
                </Link>
              </li>
              <li>
                <Link 
                  to="#" 
                  className={activeButton === '#' ? 'theme_btn' : ''}
                  onClick={() => handleButtonClick('#')}
                >
                  Services
                </Link>
              </li>
              <li>
                <Link 
                  to="/blog" 
                  className={activeButton === '/blog' ? 'theme_btn' : ''}
                  onClick={() => handleButtonClick('#industries')}
                >
                 Blog
                </Link>
              </li>
              <li>
                <Link 
                  to="#" 
                  className={activeButton === '#portfolio' ? 'theme_btn' : ''}
                  onClick={() => handleButtonClick('#portfolio')}
                >
                  Portfolio
                </Link>
              </li>
              <li>
                <Link 
                  to="/contact" 
                  className={activeButton === '/contact' ? 'theme_btn' : ''}
                  onClick={() => handleButtonClick('/contact_us')}
                >
                  Contact
                </Link>
              </li>
             
              <span className="animate_menu"></span>
            </ul>
          </div>
        </nav>
        <div className="header_links">
          <ul className="flex justify-between align-center">
            <li><Link to="tel:+14385238809"><span><img src="canada.png" alt="img" />+1 4385238809</span></Link></li>
            <li><Link to="tel:+91 7018 152390"><span><img src="india.png" alt="img" />+91 7018 152390</span></Link></li>
          </ul>
        </div>
      </div>
    </header>
  );
}

export default Header;