import { Link } from "react-router-dom";
import Header from "../components/Header";
import heroBg from '../images/hero_bg.png';
import Footer from "../components/Footer";


function Blogs() {
    const backgroundStyle = {
        backgroundImage: `url(${heroBg})`,
    };
    return (
        <>
            <Header />
            <section className="hero_secc bg-style" style={backgroundStyle}>
                <div className="container">
                    <div className="about-sec-inner">
                        <div className="about-left-content">
                            <div className="hero_content-about">
                                <h2>
                                    Blog
                                </h2>
                                <p><Link to="/">Home</Link> <span> > </span> Blog</p>
                            </div>
                        </div>
                        <div className="about-right-img">
                            <div className="hero_img-about">

                                <img src="blog.png"
                                    alt="img" />

                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <section className="blog_sec reveal">
                <div className="container">
                    <div className="blogs_row">
                        <ul>
                            <li className="single_blog">
                                <div className="blog_image">
                                    <img src="blog_image.png" alt="blog"></img>
                                </div>
                                <div className="main_blog_text">
                                    <div className="blog_text">
                                        <h5>A blend of art and science driving success</h5>
                                        <p>In the ever-evolving digital landscape, where online presence is paramount .......</p>
                                    </div>
                                    <div className="blog_date ">
                                        <div className="date_left">
                                            <p>January 15, 2024</p>
                                        </div>
                                        <div className="date_right">
                                            <Link to="">Read More</Link>
                                        </div>
                                    </div>
                                </div>
                            </li>
                            <li className="single_blog">
                                <div className="blog_image">
                                    <img src="blog_image.png" alt="blog"></img>
                                </div>
                                <div className="main_blog_text">
                                    <div className="blog_text">
                                        <h5>A blend of art and science driving success</h5>
                                        <p>In the ever-evolving digital landscape, where online presence is paramount .......</p>
                                    </div>
                                    <div className="blog_date ">
                                        <div className="date_left">
                                            <p>January 15, 2024</p>
                                        </div>
                                        <div className="date_right">
                                            <Link to="">Read More</Link>
                                        </div>
                                    </div>
                                </div>
                            </li>
                            <li className="single_blog">
                                <div className="blog_image">
                                    <img src="blog_image.png" alt="blog"></img>
                                </div>
                                <div className="main_blog_text">
                                    <div className="blog_text">
                                        <h5>A blend of art and science driving success</h5>
                                        <p>In the ever-evolving digital landscape, where online presence is paramount .......</p>
                                    </div>
                                    <div className="blog_date ">
                                        <div className="date_left">
                                            <p>January 15, 2024</p>
                                        </div>
                                        <div className="date_right">
                                            <Link to="">Read More</Link>
                                        </div>
                                    </div>
                                </div>
                            </li>
                            <li className="single_blog">
                                <div className="blog_image">
                                    <img src="blog_image.png" alt="blog"></img>
                                </div>
                                <div className="main_blog_text">
                                    <div className="blog_text">
                                        <h5>A blend of art and science driving success</h5>
                                        <p>In the ever-evolving digital landscape, where online presence is paramount .......</p>
                                    </div>
                                    <div className="blog_date ">
                                        <div className="date_left">
                                            <p>January 15, 2024</p>
                                        </div>
                                        <div className="date_right">
                                            <Link to="">Read More</Link>
                                        </div>
                                    </div>
                                </div>
                            </li>
                            <li className="single_blog">
                                <div className="blog_image">
                                    <img src="blog_image.png" alt="blog"></img>
                                </div>
                                <div className="main_blog_text">
                                    <div className="blog_text">
                                        <h5>A blend of art and science driving success</h5>
                                        <p>In the ever-evolving digital landscape, where online presence is paramount .......</p>
                                    </div>
                                    <div className="blog_date ">
                                        <div className="date_left">
                                            <p>January 15, 2024</p>
                                        </div>
                                        <div className="date_right">
                                            <Link to="">Read More</Link>
                                        </div>
                                    </div>
                                </div>
                            </li>
                            <li className="single_blog">
                                <div className="blog_image">
                                    <img src="blog_image.png" alt="blog"></img>
                                </div>
                                <div className="main_blog_text">
                                    <div className="blog_text">
                                        <h5>A blend of art and science driving success</h5>
                                        <p>In the ever-evolving digital landscape, where online presence is paramount .......</p>
                                    </div>
                                    <div className="blog_date ">
                                        <div className="date_left">
                                            <p>January 15, 2024</p>
                                        </div>
                                        <div className="date_right">
                                            <Link to="">Read More</Link>
                                        </div>
                                    </div>
                                </div>
                            </li>
                        </ul>
                    </div>
                    <div className="blog_btn">
                                <Link to="#" className="about_theme_btn">Explore More</Link>
                            </div>
                </div>
            </section>

            <Footer />
        </>
    )
};
export default Blogs;