import { Link } from "react-router-dom";
import React  from "react";
function Footer (){
    return(
        <footer class="footer_wrap reveal">
        <div class="container">
            <div class="inner_footer_sec">
                <div class="main_footer ">
                    <div class="footer_link_list footer12301">
                        <div class="footer_head">
                            <h3>Main Links</h3>
                        </div>
                        <ul>
                            <li>
                                <Link href="#">Home</Link>
                            </li>
                            <li>
                                <Link href="#">Services</Link>
                            </li>
                            <li>
                                <Link href="#">Industries</Link>
                            </li>
                            <li>
                                <Link href="#">Portfolio</Link>
                            </li>
                            <li>
                                <Link href="#">Insights</Link>
                            </li>
                            <li>
                                <Link href="#">About Us</Link>
                            </li>
                        </ul>                                       
                    </div>
                    <div class="footer_link_list footer123">
                        <div class="footer_head">
                            <h3>Services</h3>
                        </div>
                        <ul>
                            <li>
                                <Link href="#">Online Store</Link>
                            </li>
                            <li>
                                <Link href="#">Web Scrapping</Link>
                            </li>
                            <li>
                                <Link href="#">Dropshipping</Link>
                            </li>
                            <li>
                                <Link href="#">UI/UX</Link>
                            </li>
                            <li>
                                <Link href="#">Python</Link>
                            </li>
                            <li>
                                <Link href="#">Social Media</Link>
                            </li>
                        </ul>    
                    </div>
                    <div class="footer_link_list footer1230">
                        <div class="footer_head">
                            <h3>Email</h3>
                        </div>
                        <div class="menu_bars">
                        <a href="mailto:sales@exoticaitsolutions.com">sales@exoticaitsolutions.com</a>

                        </div>							
                        <div class="footer_head phone_number">
                            <h3>Phone</h3>
                            </div>      
                        <div class="menu_bars">
                            <a href="tel: +1 4385238809">UK:  +1 4385238809</a><br/>
                            <a href="tel: +91 7018 152390">India: +91 7018 152390</a> 
                        </div>
                        <div class="footer_head phone_number">
                            <h3>Address</h3>
                        </div>
                        <div class="menu_bars">
                            <p>385 Edgevalley Road London,
                            Ontario, N5v0c2 </p>
                        </div> 
                    </div>
                    <div class="footer_link_list footer120">
                        <div class="footer_head">
                            <h3>Social</h3>
                        </div>
                        <ul id="menu_quick_links_menu" class="menu_bars_bottom">
                            <li>
                                <Link to="https://www.instagram.com/exotica.itsolutions/" target="_blank"><img src="insta.png" alt="" /></Link>
                            </li>
                            <li>
                                <Link to="https://www.facebook.com/p/Exotica-It-Solutions-Pvt-Ltd-100093443127454/" target="_blank"><img src="fb.png" alt=""/></Link>
                            </li>
                            <li>
                                <Link to="https://www.youtube.com/@exoticaitsolutions" target="_blank"><img src="youtube.png" alt=""/></Link>
                            </li>
                        </ul>    
                        <div class="footer_head inform">
                            <h3>Legal Information</h3>
                        </div>
                        <ul>
                            <li>
                                <Link href="">Privacy Policy</Link>
                            </li>
                            <li>
                                <Link href="">Terms and Condition</Link>
                            </li>
                        </ul>    
                    </div>					  
                </div> 
            </div>
        </div>
        <div class="bottom_footer"> 
			<div class="container">
			<div class="inner_content_copy">
            <p className="copy">Copyright© {new Date().getFullYear()} All Rights Reserved.</p>
				</div>
			</div>
		</div>
    </footer>
    )
}
export default Footer;